<template>
    <div v-if="code && user">
        <div v-if="outputFormat">
            <div class="wizi">
                <div v-if="code.offerImage" class="wiziImage">
                    <img :src="code.offerImage" />
                </div>
                <div v-else class="wiziText biztext">
                    <h1>{{ this.code.BusName }}</h1>
                </div>
                <div v-if="code.usemapbutton && code.usemapbutton == 1 || code.usemapbutton && code.usemapbutton == 1 || code.usemapbutton && code.usemapbutton == 1"
                    class="wizibuttons">
                    <div class="row">
                        <div class="btn-group btn-group-justified" role="group" aria-label="Ad Action Buttons">
                            <div v-if="code.usemapbutton && code.usemapbutton == 1">
                                <div class="btn-group" role="group">
                                    <a :href="code.locationurl">
                                        <button type="button" class="btn btn-default" id="admap">MAP</button>
                                    </a>
                                </div>
                            </div>
                            <div v-if="code.usemapbutton && code.usemapbutton == 1">
                                <div class="btn-group" role="group">
                                    <a style="text-decoration: none; color:white;" :href="'tel:' + code.phone">
                                        <button type="button" class="btn btn-default" id="adcall">CALL</button>
                                    </a>
                                </div>
                            </div>
                            <div v-if="code.usemapbutton && code.usemapbutton == 1">
                                <div class="btn-group" role="group">
                                    <a style="text-decoration:none; color:white;" :href="code.desturl">
                                        <button type="button" class="btn btn-default" id="adweb">WEB</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else> <!-- non-wizi -->
            <h1>Codes Details</h1>
            <h2>{{ code.BusName }}</h2>
            <p>Owner Username: {{ user.username }}</p>
            <p
                v-if="code && code.redirecturl && code.redirecturl != '' && !code.redirecturl.toLowerCase().includes('null')">
                RedirectURL:
                <a target="_blank" :href="code.redirecturl">{{ code.redirecturl.replace('http://', 'https://') }}</a>
            </p>
            <p v-if="code && code.phone && code.phone != ''">Phone:
                <a target="_blank" :href="'call:' + code.phone">{{ code.phone }}</a>
            </p>
            <p
                v-if="code && ((code.locationurl && code.locationurl != '' && !code.locationurl.toLowerCase().includes('null')) || (code.location && code.location != '' && !code.location.toLowerCase().includes('null')))">
                Location:
                <br />
                <span
                    v-if="code && code.locationurl && code.locationurl != '' && !code.locationurl.toLowerCase().includes('null')">LocationURL:
                    <a target="_blank" :href="code.locationurl.replace('http://', 'https://')">{{
                        code.locationurl.replace('http://', 'https://') }}</a></span>
                <br />
                <span
                    v-if="code.location && code.location != '' && !code.location.toLowerCase().includes('null')">Location:{{
                        code.location }}</span>
            </p>
            <p v-if="code && code.desturl && code.desturl != '' && !code.desturl.toLowerCase().includes('null')">DestURL
                (was WEB):
                <a target="_blank" :href="code.desturl.replace('http://', 'https://')">{{
                    code.desturl.replace('http://', 'https://') }}</a>
            </p>
            <p> The Code is {{ codeidQuery }} - {{ codeid }} - {{ code.codeid }}</p>
        </div>
    </div>
    <div v-else>
        <p>Loading code details... {{ codeidQuery }} - {{ codeid }}</p>
    </div>
</template>

<script>
export default {
    name: 'CodeDetails',
    display: "Codes Details",
    props: ["codeid"],
    data() {
        return {
            imgsrc: "",
            code: null,
            user: null,
            codeidQuery: this.codeid,
            outputFormat: this.$route.query.of,
        }
    },
    mounted() {
        if (this.outputFormat == 1) {
            console.log("outputFormat 1")
            //jquery
            let jqueryScript = document.createElement('script')
            jqueryScript.setAttribute('src', 'https://ajax.googleapis.com/ajax/libs/jquery/2.1.4/jquery.min.js')
            document.head.appendChild(jqueryScript)
            var jqueryCSS = document.createElement("link")
            jqueryCSS.setAttribute("rel", "stylesheet")
            jqueryCSS.setAttribute("type", "text/css")
            jqueryCSS.setAttribute("href", "https://ajax.googleapis.com/ajax/libs/jquery/2.1.4/jquery.min.js")
            document.getElementsByTagName("head")[0].appendChild(jqueryCSS)
            let jqueryUI = document.createElement('script')
            jqueryScript.setAttribute('src', 'https://ajax.googleapis.com/ajax/libs/jqueryui/1.11.4/jquery-ui.min.js')
            document.head.appendChild(jqueryUI)
            //BootStrap
            var bootstrapCSS = document.createElement("link")
            bootstrapCSS.setAttribute("rel", "stylesheet")
            bootstrapCSS.setAttribute("type", "text/css")
            bootstrapCSS.setAttribute("href", "https://maxcdn.bootstrapcdn.com/bootstrap/3.3.4/css/bootstrap.min.css")
            document.getElementsByTagName("head")[0].appendChild(bootstrapCSS)
            var bootstrapTheme = document.createElement("link")
            bootstrapTheme.setAttribute("rel", "stylesheet")
            bootstrapTheme.setAttribute("type", "text/css")
            bootstrapTheme.setAttribute("href", "https://maxcdn.bootstrapcdn.com/bootstrap/3.3.4/css/bootstrap-theme.min.css")
            document.getElementsByTagName("head")[0].appendChild(bootstrapTheme)
            let bootstrapScript = document.createElement('script')
            bootstrapScript.setAttribute('src', 'https://maxcdn.bootstrapcdn.com/bootstrap/3.3.4/js/bootstrap.min.js')
            document.head.appendChild(bootstrapScript)
        } else {
            console.log("no outputFormat")
        }

        fetch("http://localhost:3000/codes?codeid=" + this.codeidQuery)
            .then(res => res.json())
            .then(data => this.code = data[0])
            .then(() => {
                console.log(this.code)
            })
            .then(() => {
                console.log(this.code.owneruserid)
                fetch("http://localhost:3000/users?id=" + this.code.owneruserid)
                    .then(res => res.json())
                    .then(data => this.user = data[0])
                    .then(() => {
                        console.log(this.user)
                    })
                    .catch(err => {
                        console.log(err.message)
                    })
            })
            .catch(err => {
                console.log(err.message)
            })
        console.log(`code data loaded.  `);
    },
    components: {

    }

}
</script>

<style>
body {
    margin: 0;
    font-size: 13px;
    line-height: 1.231;
}

body,
button,
input,
select,
textarea {
    font-family: sans-serif;
    color: #222;
}

a:hover,
a:active {
    outline: 0;
}

img {
    border: 0;
    -ms-interpolation-mode: bicubic;
    vertical-align: middle;
}

wizi {
    background-color: #f5f5f5;
}

div.wiziImage {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}

Footer {
    background-color: #f5f5f5;
}

.whiteboxshadow {
    color: black;
    background-color: #f5f5f5;
    box-shadow: 4px 4px 4px #919191;
}

.buttonrow {
    height: 80px;
}

.adtoprow {
    height: 5px;
}

.biztext {
    font-size: 1em;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}

.bizinfocontainer {
    height: 100%;
    width: 100%;
}

.bizoffercontainer {
    height: 40%;
    width: 100%;
}

.btn {
    font-weight: bold;
    /*color:red;*/
    font-size: 2em;
}
</style>