<template>
<p>Reloading</p>
</template>

<script>
import { useRouter } from 'vue-router'
import { useCookies } from "vue3-cookies"
const { cookies } = useCookies()

export default {
    mounted() {
        const router = useRouter();

        this.interval = setTimeout(
            function () {
                router.push({ path: "/" });
            },
            50
        );
    },
/*    
  setup() {
    const router = useRouter();

    const reloadHomePage = () => {
      router.push('/'); // Navigate to the home page
    };

    return {
      reloadHomePage
    };
  }*/
};
</script>