import { createRouter, createWebHistory } from "vue-router"
import NotFound404 from "@/views/NotFound404.vue"
import NotAuthorized from "@/views/NotAuthorized.vue"
import HomeView from "@/views/HomeView.vue"
import AboutView from "@/views/AboutView.vue"
import LoginView from "@/views/LoginView.vue"
//Lazy import CodesView from "@/views/codes/CodesView.vue";
import CodeDetailsView from "@/views/codes/CodeDetailsView.vue"
import AddCodeView from "@/views/codes/AddCodeView.vue"
import Reload from "@/views/Reload.vue"
import Auth from "@/views/Auth.vue"
import Logout from "@/views/Logout.vue"
import { useRoute } from "vue-router";
import { useCookies } from "vue3-cookies"
const { cookies } = useCookies();

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
        meta: {
            display: "Home",
        },
    },
    {
        path: "/codes",
        name: "codes",
        component: () => import("../views/codes/CodesView.vue"),
        meta: {
            display: "Codes",
            title: "QRCE Codes",
        },
    },
    {
        path: "/code/:codeid?",
        name: "CodeDetails",
        component: CodeDetailsView,
        meta: {
            hideNavbar: true,
        },
        props: true,
    },
    {
        path: "/addcode",
        name: "add code",
        component: AddCodeView,
        meta: {
            display: "Add Code",
            title: "QRCE Add Code",
            authRequired: true,
        },
        beforeEnter: (to, from, next) => {
            let authCookie = cookies.get("theAuthCookie");
            //add code here to validate auth here.
            if (authCookie) {
                // Replace 'yourCondition' with your logic
                next(); // Allow navigation
            } else {
                next("/");
            }
        },
    },
    {
        path: "/about",
        name: "about",
        component: AboutView,
        //use meta to pre-set Nav button value when lazy loading
        meta: {
            display: "About",
            title: "QRCE About",
        },
    },
    {
        path: "/logout",
        name: "logout",
        component: Logout,
        //use meta to pre-set Nav button value when lazy loading
        meta: {
            display: "Sign Out",
            title: "Sign Out",
            authRequired: true,
        },
    },
    {
        path: "/reload",
        name: "reload",
        component: Reload,
        //use meta to pre-set Nav button value when lazy loading
        meta: {
            display: "Reload",
            title: "Reload",
            hideNavbar: true,
        }
    },
    {
        path: "/auth",
        name: "auth",
        component: Auth,
        //use meta to pre-set Nav button value when lazy loading
        meta: {
            hideNavbar: true,
        },
    },
    {
        path: "/notauthorized",
        name: "notauthorized",
        component: NotAuthorized,
        //use meta to pre-set Nav button value when lazy loading
        meta: {
            hideNavbar: true,
        },
    },
    //catch all 404
    {
        path: "/:catchAll(.*)",
        name: "notfound",
        component: NotFound404,
        meta: {
            hideNavbar: true,
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
router.beforeEach((to, from, next) => {
    document.title = to.meta.title || "QR Code Experts"; // Set title based on route meta or default
    next();
});

/*
router.beforeEach((to, from, next) => {
    if (
        to.name !== "login"
        //    && !isAuthenticated
    )
        next({ name: "login" })
    else next();
});
*/

export default router;
