<template>
    <form @submit.prevent="handleSubmit">
        <label>Business Name:</label>
        <input type="text" required v-model="BusName">
        <label>Web URL</label>
        <input type="text" v-model="desturl">
        <label>Phone Number</label>
        <input type="text" v-model="phone">
        <label>Location</label>
        <input type="text" v-model="location">
        <label>UPC Code</label>
        <input @keyup="checkUPC" type="text" v-model="upc">
        <div id="upcError">{{ upcError }}</div>
        <input type="checkbox" v-model="calcCheckDigit" default="false">
        <label>Calculate Check Digit?</label>
        <button>Add Code</button>
        <div>{{ codeid }}</div>
        <qrcode-vue id="qrcode" :value="sourceurl" :image-settings="imageSettings" /><br />
        <Vue3Barcode id="upc" :value="mappedUpc" format="UPC">No UPC value.</Vue3Barcode>
        <!--<button @click.prevent="getQRCodeData">Get QR Code</button>
        <button @click.prevent="getUPCData">Get UPC</button>
        -->
        <button v-if="mappedUpc" @click.prevent="updateOverlaidImage">Combine</button>
        <img v-if="qrCodeImageData" :src="qrCodeImageData" class="qrCodeImage" id="qrCodeCopy" /><br />
        <img v-if="upcImageData" :src="upcImageData" class="upcImage" id="upcCopy" /><br />
        <canvas class="overlaidImage" id="overlaidImage" width="242" height="142"></canvas>

    </form>
</template>

<script>
import { v4 as uuidv4, v6 as uuidv6 } from 'uuid';
//import { QRCode } from 'easyqrcodejs-nodejs';
import QrcodeVue from 'qrcode.vue'
import { component, ref, onUpdated } from 'vue';
import Vue3Barcode from 'vue3-barcode';
import domtoimage from 'dom-to-image';
import { toPixelData, toPng } from 'html-to-image';

function calculateUPCCheckDigit(upc) {
    // Ensure the UPC is a string and remove any non-digit characters
    upc = upc.toString().replace(/\D/g, '');

    // Check if the UPC has the correct length (11 digits)
    if (upc.length !== 11) {
        return "Invalid UPC length";
    }

    // Calculate the check digit
    let sumOdd = 0;
    let sumEven = 0;

    for (let i = 0; i < upc.length; i++) {
        const digit = parseInt(upc[i]);

        if ((i + 1) % 2 === 1) {
            sumOdd += digit;
        } else {
            sumEven += digit;
        }
    }

    const total = sumOdd * 3 + sumEven;
    const checkDigit = (10 - (total % 10)) % 10;

    return checkDigit;
}

function isNumeric(str) {
    const num = Number(str);
    return !isNaN(num) && isFinite(num);
}

const coverImg = (ctx, windo, img, type) => {
    const win = {
        w: windo.width,
        h: windo.height,
    }
    const imgRatio = img.height / img.width
    const winRatio = windo.height / windo.width
    if ((imgRatio < winRatio && type === 'contain') || (imgRatio > winRatio && type === 'cover')) {
        const h = windo.width * imgRatio
        ctx.drawImage(img, 0, (windo.height - h) / 2, windo.width, h)
    } else
        if ((imgRatio > winRatio && type === 'contain') || (imgRatio < winRatio && type === 'cover')) {
            const w = windo.width * winRatio / imgRatio
            ctx.drawImage(img, (win.w - w) / 2, 0, w, windo.height)
        } else {
            ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, img.width, img.height)
        }
    return ctx;
}

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

// Save the barcode to a file
//const fs = require('fs');
//fs.writeFileSync('barcode.png', buffer); 

//https://qrcodeexperts.net/code?ad=undefined
//https://qrcodeexperts.net/code?ad=1ef833d7-b355-6070-b7b7-f09a2671576b

export default {
    components: {
        QrcodeVue,
        Vue3Barcode,
    },
    data() {
        return {
            BusName: '',
            desturl: '',
            phone: '',
            location: '',
            upc: '12345678901',
            mappedUpc: '',
            codeid: uuidv6(),
            sourceurl: '',
            //imageData: null,
            imageSettings: {
                //src: new URL('../../assets/logo-qrcodeexperts.png', import.meta.url).href,
                //excavate: true, // Cuts out the logo from the QR code
                width: 25,
                height: 25,
            },
            calcCheckDigit: false,
            upcError: '',
            upcBarcode: '',
            qrCodeImageData: null,
            upcImageData: null,
            overlaidData: null,
        }
    },
    methods: {
        checkUPC(evt) {
            let res
            //console.log(evt)
            if (!isNumeric(this.upc)) {
                this.upcError = "UPC must be a number"
                this.mappedUpc = ''
            }
            if (isNumeric(this.upc) && this.upc.length < 11) {
                this.upcError = "UPC must be at least 11 digits long"
                this.mappedUpc = ''
            }
            if (isNumeric(this.upc) && this.upc.length == 11) {
                res = calculateUPCCheckDigit(this.upc)
                this.upcError = `UPC with check digit - ${res} - ${this.upc}${res}`
                this.mappedUpc = ''
            }
            if (isNumeric(this.upc) && this.upc.length == 11 && this.calcCheckDigit) {
                res = calculateUPCCheckDigit(this.upc)
                this.upc = this.upc + res
                this.upcError = ''
                this.mappedUpc = this.upc
            }
            if (this.upc.length == 12) {
                this.upcError = ''
                this.mappedUpc = this.upc
            }

        },
        handleSubmit() {
            console.log(this.BusName, this.desturl, this.phone, this.location, this.upc)
        },
        getQRCodeData() {
            return new Promise(resolve => {
                let qrcodeEle = document.getElementById('qrcode');
                let imageData = null;
                toPng(qrcodeEle)//, { width: svg.width.baseVal.value, height: svg.height.baseVal.value })
                    .then((data) => {
                        imageData = data
                        this.qrCodeImageData = data
                        console.log(imageData)
                        resolve();
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            })
        },
        getUPCData() {
            return new Promise(resolve => {
                let upcDiv = document.getElementById('upc')
                let svg = upcDiv.children[0]
                let upcImageData;
                toPng(svg)//, { width: svg.width.baseVal.value, height: svg.height.baseVal.value })
                    .then((data) => {
                        upcImageData = data
                        this.upcImageData = data
                        console.log(upcImageData)
                        resolve();
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            })
        },
        overlayImages() {
            return new Promise(async resolve => {
                const canvas = document.getElementById('overlaidImage')
                let ctx = canvas.getContext('2d')
                const upcCopyImg = document.getElementById("upcCopy")
                const qrCodeImg = document.getElementById("qrCodeCopy")
                await sleep(1000)
                ctx.drawImage(upcCopyImg, 0, 0, upcCopyImg.width, upcCopyImg.height)
                //await sleep(1000)
                ctx.drawImage(qrCodeImg, (242 / 2) - 25, (142 / 2) - 10, qrCodeImg.width, qrCodeImg.height)
                //upcCopyImg.style.display = "none"
                //qrCodeImg.style.display = "none"
                resolve()

                /*
                ctx = coverImg(ctx, canvas, upcCopyImg, 'contain');
                ctx.drawImage(upcCopyImg, 0, 0, upcCopyImg.width, upcCopyImg.height, 0, 0, 242, 142);
                let x = Math.floor(upcCopyImg.width / 2) - Math.floor(qrCodeImg.width / 2)
                let y = Math.floor(upcCopyImg.height / 2) - Math.floor(qrCodeImg.height / 2) + 5
                ctx.drawImage(qrCodeImg, x, y, qrCodeImg.width, qrCodeImg.height, x, y, 60, 60)
                */
            })
        },
        updateOverlaidImage() {
            if (this.mappedUpc && this.mappedUpc.length == 12) {
                this.getQRCodeData()
                    .then(() => {
                        console.log(`getQRCodeData complete. getUPCData next.`)
                        this.getUPCData()
                            .then(() => {
                                console.log(`getUPCData complete. overlayImages next.`)
                                this.overlayImages()
                                    .then(() => {
                                        console.log(`overlayImages complete. work complete.`)
                                    }).catch((err) => {
                                        console.log(`overlayImages call err ${err}`)
                                    })
                            })
                            .catch((err) => {
                                console.log(`getUPCData call error ${err}`)
                            })
                    })
                    .catch((err) => {
                        console.log(`getQRCodesData call error: ${err}`)
                    })

            }
        }
    },
    mounted() {
        this.sourceurl = `https://qrcodeexperts.net/code?ad=${this.codeid}`
        let setupUpc = ref('#upc')

        if (setupUpc.children && setupUpc.children[0]) {
            setupUpc.children[0].onChange((evt) => {
                let event = evt
                console.log(`child changed. ${event}`)
            })
        }

    },
    onChange() {
        console.log(`This is a test.... onChange`)
    },
    setup() {
        /*        let setupUpc = ref('#upc')
        
                if (setupUPC && setupUpc.children && setupUpc.children[0]) {
                    setupUpc.children[0].onChange((evt) => {
                        let event = evt
                        console.log(`child changed. ${event}`)
                    })
                }*/

        onUpdated(() => {
            let upcEle = document.getElementById('upc');
            // text content should be the same as current `count.value`
            if (upcEle && upcEle.children && upcEle.children[0]) {
                if (upcEle.children[0].onchange == null) {
                    upcEle.children[0].onchange = function (evt) {
                        let event = evt
                        console.log(`child changed. ${event}`)
                    }
                }
            }

            console.log(upcEle.textContent)
        })
    }

}
</script>

<style>
form {
    background: rgb(229, 229, 229);
    padding: 20px;
    border-radius: 10px;
}

label {
    display: block;
    color: black;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    letter-spacing: 1px;
    margin: 20px 0 10px 0
}

input {
    padding: 10px;
    border: 0;
    border-bottom: 1px solid #ddd;
    width: 100%;
    box-sizing: border-box;
}

textarea {
    border: 1px solid #ddd;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    height: 100px;
}

form button {
    display: block;
    margin: 20px auto 0;
    background: #00ce89;
    color: white;
    padding: 10px;
    border: 0;
    border-radius: 6px;
    font-size: 16px;
}

.qrCodeImage {
    width: 50px;
    height: 50px;
    /*display: none;*/
}

.upcImage {
    width: 242px;
    height: 142px;
    /*display: none;*/
}

.overlaidImage {
    width: 242px;
    height: 142px;
}
</style>