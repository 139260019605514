<template>
    <div class="home">
        <h1>{{ this.description }}</h1>
        <div class="heading">
            <h2>UPC-QR CODE<br />
                Masters</h2>
            <h3>Our system makes a re-directable QR code with
                your UPC code!</h3>
            <h4 class="italicH4">Handle marketing and SKU
                management in one space!</h4>
            <p>Our company will train your staff to operate the system easily and quickly.</p>
            <!--<div>-->
            <img class="barcode" alt="Mixed Code" src="../assets/UPCandQR.png" />
            <!--</div>-->
            <div class="footer">
                <div style="text-align: center;">
                    <small><small><small>
                                <p>System is licensed for authorized customers.<br />Unauthorized use subject to legal
                                    recourse.<br />&copy; {{ new Date().getFullYear() }}, &reg; &amp; Powered by
                                    <a class="footer" href="http://qrcodeexperts.com">JT.ADMAN. QRCodeExperts.net
                                    </a>
                                    <!--<a href="http://www.jtadman.com"> <img class="footerLogo" alt="JTAdMan"
                                            src="../assets/jt-adman-logo-s.png" />
                                    </a>-->
                                </p>
                            </small></small></small>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'HomeView',
    display: "Home",
    data() {
        return {
            description: "QRCodeExperts.Net"
        }
    },
    components: {
    }
}
</script>

<style>
h4.italicH4 {
    font-style: italic;
}

a.footer {
    font-weight: bold;
    color: white;
    text-decoration: none;
    padding: 10px;
}

div.heading {
    color: white;
    margin: 0 auto;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background: rgb(46, 108, 183);
    width: 300px;
}

img.barcode {
    width: 100%;
    object-fit: contain;
}

img.footerLogo {
    vertical-align: middle;
}
</style>
