<template>
    <div class="about">
        <h1>{{ this.description }}</h1>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus non expedita, iste pariatur odio amet, vel
            recusandae autem quam rem dolorem. Aliquam harum doloremque veritatis amet ipsam animi vitae assumenda nulla
            sapiente cupiditate sint voluptatem, maxime ex in eaque asperiores.</p>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia tenetur repellat quod odio aliquam architecto
            voluptas quaerat consectetur culpa nihil nobis modi quisquam esse vero repudiandae, illum maiores
            necessitatibus tempora deserunt explicabo sint cupiditate a dicta? Excepturi aperiam molestias odio mollitia
            aliquid recusandae nisi. Fugit ipsa nihil temporibus natus quibusdam perspiciatis quisquam ipsam possimus
            commodi? Est harum maiores libero, eos commodi ab id nihil quod ipsa voluptatibus at possimus reiciendis,
            deserunt vel fugit molestiae nisi magnam asperiores? Perspiciatis minus quaerat debitis eos ea! Optio
            aperiam, suscipit assumenda ratione accusamus quam odio laboriosam reprehenderit ea illum dolor quaerat, rem
            necessitatibus expedita? Necessitatibus error provident veritatis atque vero quaerat voluptatibus distinctio
            expedita molestiae quisquam accusantium quae suscipit repellendus temporibus ut rerum, eligendi laboriosam
            quibusdam perferendis recusandae explicabo enim ex. Deserunt laudantium eum facilis ipsum harum tenetur
            quis. Maiores porro tempore alias mollitia!</p>
    </div>
</template>

<script>
export default {
    name: 'AboutView',
    //only used in Nav after lazy load.
    display: "About",
    data() {
        return {
            description: "About Us"
        }
    },
    components: {

    }
}
</script>
