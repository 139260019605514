<template>
    <p>Logging out</p>
</template>

<script>
import { useRouter } from 'vue-router'
import { useCookies } from "vue3-cookies"
const { cookies } = useCookies()

export default {
    mounted() {
        const router = useRouter()
        cookies.remove("theAuthCookie")
        const next = "/reload"
        this.interval = setTimeout(
            function () {
                router.push({ path: next });
            },
            50
        );
    }
}
</script>