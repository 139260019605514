<template>
    <div class="top">
        <div>
            <router-link to="/"><img alt="QRCodeExperts.net" src="./assets/logo-qrcodeexperts.png"
                    class="logo" /></router-link>
        </div>
        <Navbar :key="$route.fullPath" />
    </div>

    <router-view />
</template>

<script>
import Navbar from "./components/Navbar.vue"

export default {
    components: { Navbar },
}
</script>

<style>
body {
    background: #b0b9bb;
    margin: 8px;
}

#app {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    background: #b0b9bb;
}

.top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    width: 80px;
}
</style>
