<template>
    <h2>Sorry...</h2>
    <h3>You are not authorized</h3>
</template>

<script>
import router from "../router";

export default {
    data() {
        return {
            interval: null
        }
    },
    created() {
        this.interval = setTimeout(
            function () {
                router.push({ path: "/" });
            },
            10000
        );
    },
}

</script>